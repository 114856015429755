/* Dropdown Content */
.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px; /* Adjust as needed */
    overflow-y: auto;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  /* Panel Content */
  .panel-content {
    padding: 10px;
  }
  
  /* Select Panel */
  .select-panel {
    display: flex;
    flex-direction: column;
  }
  
  /* Search */
  .search {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .search input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .search-clear-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 8px;
  }
  
  .search-clear-button svg {
    color: #888;
    transition: color 0.2s ease;
  }
  
  .search-clear-button:hover svg {
    color: #333;
  }
  
  /* Options */
  .options {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Select Item */
  .select-item {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .select-item:hover {
    background-color: #f0f0f0;
  }
  
  /* Item Renderer */
  .item-renderer {
    display: flex;
    align-items: center;
  }
  
  .item-renderer input {
    margin-right: 8px;
  }
  
  .item-renderer span {
    font-size: 14px;
    color: #333;
  }
  
  /* Options in Dropdown */
  .select-item[aria-selected="true"] {
    background-color: #e0f7fa;
    color: #00796b;
  }
  
  /* Labels */
  .select-item label {
    display: block;
    width: 100%;
  }
  
  /* Slider Container */
.rc-slider {
    width: 100%;
    position: relative;
    height: 16px; /* Increase the height to make the slider bigger */
  }
  
  /* Rail */
  .rc-slider-rail {
    height: 12px; /* Adjust thickness of the rail */
    background-color: #e0e0e0; /* Light grey background for rail */
    border-radius: 4px; /* Rounded corners for the rail */
  }
  
  /* Track */
  .rc-slider-track {
    height: 12px; /* Matches rail thickness */
    background-color: #4db6ac; /* Custom color for the track */
    border-radius: 4px; /* Matches rail rounding */
  }
  
  /* Handles */
  .rc-slider-handle {
    width: 24px; /* Size of the handle */
    height: 24px; /* Size of the handle */
    border-radius: 50%; /* Make the handle circular */
    background-color: #ffffff; /* Handle background color */
    border: 2px solid #4db6ac; /* Border color for the handle */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for a more 3D effect */
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    opacity: 1;
  }
  
  /* Handle Active State */
  .rc-slider-handle-1:focus,
  .rc-slider-handle-2:focus,
  .rc-slider-handle-1:hover,
  .rc-slider-handle-2:hover {
    background-color: #ffffff; /* Handle color when active or hovered */
    border-color: #00796b; /* Darker border when active or hovered */
  }
  
  /* Steps (If applicable) */
  .rc-slider-step {
    height: 4px; /* Matches rail and track thickness */
    background-color: transparent; /* Default is transparent */
  }

  .rc-slider-mark {
    top: 30px;
    font-size: 16px;
    font-weight: bold;
  }

  .rc-slider-mark-text-active {
    color: #000;
  }

  /* Override the default .item-renderer styles */
.rmsc .item-renderer {
    display: flex;
    align-items: center;
}

/* Style the <span> inside .item-renderer */
.rmsc .item-renderer span {
    font-size: 14px;
    color: #333;
    margin-top: 1px;
}

.rmsc .options {
  max-height: 210px;
}