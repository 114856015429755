.bg-body-tertiary {
    background-color: #fff !important;

}

.navbar_color_default, .navbar_color_default a {
    color: #5e666f;
}

.navbar_margin_not_mobile {
    margin: 0px 10px;
}