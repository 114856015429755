.react-tabs__tab:focus:after {
    background: transparent !important;
}

.react-tabs__tab--selected { 
    background-color: #A8D5BA !important;
}

.react-tabs__tab-list {
    border-bottom: transparent !important;
}

.react-tabs__tab--selected {
    border-bottom: 1px solid #aaa !important;
  }

.react-tabs__tab--disabled {
    background-color: #d3d3d3 !important; /* Grey background for disabled tab */
    color: #888 !important;               /* Lighter text color */
    cursor: not-allowed !important;        /* Show 'not-allowed' cursor */
    pointer-events: none;   
}

