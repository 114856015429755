/* InputNumberStyles.css */

/* Adjust the control wrapper to align with the input */
.rc-input-number-handler-wrap {
    width: 22px;
    right: 0; /* Align to the right */
    top: 0; /* Align with the input field */
    height: 100%; /* Ensure full height of the input */
    display: flex;
    flex-direction: column;
  }
  
  /* Style the up and down buttons */
  .rc-input-number-handler {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Light grey background for buttons */
    border-left: 1px solid #ddd; /* Slight border on the left */
    cursor: pointer;
  }
  
  .rc-input-number-handler-up-inner,
  .rc-input-number-handler-down-inner {
    font-size: 12px;
    color: #333; /* Darker color for arrow icons */
  }
  
  /* Add hover effect for better user experience */
  .rc-input-number-handler:hover {
    background-color: #e0e0e0; /* Slightly darker on hover */
  }
  
  .rc-input-number-handler-up,
  .rc-input-number-handler-down {
    flex: 1; /* Ensure each button takes up half of the control's height */
  }
  
  /* Remove all borders from the input and controls */
  .rc-input-number-handler-wrap,
  .rc-input-number-handler-up,
  .rc-input-number-handler-down {
    border: none !important; /* Remove borders from input and controls */
  }
  
  /* Prevent any border effects on hover for the controls */
  .rc-input-number:hover .rc-input-number-handler-up,
  .rc-input-number:hover .rc-input-number-handler-down,
  .rc-input-number-handler-wrap:hover {
    border: none !important; /* Disable border change on hover */
    box-shadow: none !important; /* Ensure no shadow effects either */
  }

  .rc-input-number-input {
    font-weight: bold;
    font-size: 20px;
  }

  .rc-input-number {
    border: none !important
  }
  