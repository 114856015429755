/* Custom checkbox styling */
input[type="checkbox"] {
    appearance: none; /* Remove the default checkbox styling */
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 3px;
    display: flex; /* Use flexbox to center the content */
    justify-content: center;
    align-items: center;
  }
  
  input[type="checkbox"]:checked {
    background-color: #A8D5BA; /* Set your custom color here */
    border-color: #A8D5BA; /* Match border to background color */
  }
  
  input[type="checkbox"]:checked::before {
    content: '✔'; /* You can add a custom checkmark icon */
    color: #000000;
    font-size: 14px;
    display: block;
  }